<template>
  <div class="modal fade" :id="modalId" tabindex="-1" role="dialog">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">{{ $t(title) }}</h5>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
            @click="cancelAction"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <p>{{ $t(message) }}</p>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-secondary"
            data-dismiss="modal"
            @click="cancelAction"
          >
            {{ $t('cancel') }}
          </button>
          <button type="button" class="btn btn-danger" @click="confirmAction" :disabled="loading">
            <div class="d-flex align-items-center">
               <span>{{ $t('yes') }}</span>
               <div v-if="loading" class="spinner-border spinner-border-sm float-right ml-1" role="status" aria-hidden="true"></div>
            </div>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

 <script>
export default {
  props: {
    modalId: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      default: "confirm_action",
    },
    message: {
      type: String,
      default: "irreversible_action",
    },
  },
  data() {
    return {
      loading: false,
    };
  },
  mounted() {
    $("#" + this.modalId).on("show.bs.modal", this.handleModalShow);
  },
  beforeDestroy() {
    $("#" + this.modalId).off("show.bs.modal", this.handleModalShow);
  },
  methods: {
    confirmAction() {
      this.loading = true;
      this.$emit("actionConfirmed");
    },
    cancelAction() {
      this.loading = false;
      this.$emit("actionCancelled");
    },
    handleModalShow() {
      this.loading = false;
    },
  },
};
</script>

 <style>
</style>
