<template>
   <ul class="pagination pagination-sm m-0 float-right">
      <li
         :class="{ 'page-item': true, disabled: isFirstPage }"
         @click="loadPage('first')"
      >
         <a class="page-link" href="#"> &langle;&langle; </a>
      </li>
      <li
         :class="{ 'page-item': true, disabled: isFirstPage }"
         @click="loadPage('prev')"
      >
         <a class="page-link" href="#"> &langle; </a>
      </li>

      <!-- adjacentes pages -->
      <div v-if="isLoading" class="spinner-border text-primary spinner-border-sm float-right mx-1 mt-2" role="status"
           aria-hidden="true"> </div>
      <div v-else>
         <div v-if="totalPages > 5" class="d-flex">
            <li
               v-for="page in adjacentPages"
               :key="page.page"
               :class="{ 'page-item': true, active: page.page === currentPage }"
            >
               <a class="page-link" href="#" @click="loadPage(page.page)">{{ page.page }}</a>
            </li>
         </div>
         <div v-else class="d-flex">
            <li
               v-for="page in tokenPages"
               :key="page.page"
               :class="{ 'page-item': true, active: page.page === currentPage }"
            >
               <a class="page-link" href="#" @click="loadPage(page.page)">{{ page.page }}</a>
            </li>
         </div>
      </div>

      <li
         :class="{ 'page-item': true, disabled: isLastPage }"
         @click="loadPage('next')"
      >
         <a class="page-link" href="#"> &rang; </a>
      </li>
      <li
         :class="{ 'page-item': true, disabled: isLastPage }"
         @click="loadPage('last')"
      >
         <a class="page-link" href="#"> &rang;&rang; </a>
      </li>
   </ul>
</template>

 <script>
export default {
   props: {
      totalPages: {
         type: Number,
         required: true,
      },
      currentPage: {
         type: Number,
         required: true,
      },
      isFirstPage: {
         type: Boolean,
         required: true,
      },
      isLastPage: {
         type: Boolean,
         required: true,
      },
      adjacentPages: {
         type: Array,
         required: true,
      },
      tokenPages: {
         type: Array,
         required: true,
      },
      isLoading: {
         type: Boolean,
         default: false
      }
   },
   methods: {
      loadPage(action) {
         this.$emit('page-selected', action);
      },
   }
}
</script>

 <style scoped>
</style>
